<script>
import appConfig from "@/app.config";
import { Modal } from "bootstrap";
import PageHeader from "@/components/page-header";

export default {
    page: {
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        PageHeader,
    },
    data() {
        return {

            posHubs: [],
            page: 1,
            limit: 20,
            tot_pages: 0,
            searchModel: "",
            PosHubsSearchMode: false,
            posStations: [],
            posStations_page: 1,
            posStations_limit: 20,
            posStations_tot_page: 0,
            posStations_searchModel: '',
            PosStationsSearchMode: false,

            posStationsModal: {},
            current_pos_hub_id: ''

        };
    },
    methods: {




        search() {
            this.PosHubsSearchMode = true;
            this.tot_pages = 0;
            this.http
                .post("pos-hubs/search", {
                    search: this.searchModel,
                    limit: this.limit,
                    page: this.page,
                    col: "name",
                    user_id: this.$route.params.id

                })
                .then((res) => {
                    this.tot_pages = Math.ceil(res.tot / this.limit);
                    this.posHubs = res.data;

                });
        },
        cancelAppSearchMode() {
            this.searchModel = "";
            this.PosHubsSearchMode = false;
            this.getposHubs(this.page);
        },





        getposHubs(page) {
            this.http
                .post("pos-hubs/paginate", {
                    limit: this.limit,
                    page: page,
                    user_id: this.$route.params.id
                }, null, true)
                .then((res) => {
                    this.tot_pages = Math.ceil(res.tot / this.limit);
                    this.posHubs = res.data;
                    console.log('tot :', this.tot_pages)
                });
        },

        /*  pos stations methods */
        getPosStations(page) {
            this.http
                .post("pos-stations/paginate", {
                    limit: this.posStations_limit,
                    page: page,
                    pos_hub_id: this.current_pos_hub_id
                }, null, true)
                .then((res) => {
                    this.posStations_tot_page = Math.ceil(res.tot / this.posStations_limit);
                    this.posStations = res.data;
                });
        },


        searchPosStations() {
            this.PosStationsSearchMode = true;
            this.posStations_tot_page = 0;
            this.http
                .post("pos-stations/search", {
                    search: this.posStations_searchModel,
                    limit: this.posStations_limit,
                    page: this.posStations_page,
                    col: "name",
                    pos_hub_id: this.current_pos_hub_id

                })
                .then((res) => {
                    this.posStations = res.data;
                });
        },
        cancelPosStationsSearchMode() {
            this.searchModel = "";
            this.PosStationsSearchMode = false;
            this.getPosStations(this.posStations_page);
        },
        openPosStationsModal(record) {
            this.current_pos_hub_id = record.id
            this.getPosStations(this.posStations_page)
            console.log(record)
            this.posStationsModal.show()
        },

        /* end */
    },
    created() {
        this.getposHubs(1);
    },
    mounted() {
        this.posStationsModal = new Modal("#posStationsModal")
    },
};
</script>

<template>
    <PageHeader v-if="!$route.params.id" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        :title="$t('menu.menuitems.pos_hubs.subItems.pos_hubs')" />
    <div class="row mt-4">
        <div class="col-6 mb-3">
            <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                    <input @keyup="search()" type="text" class="form-control fa-lg text-light"
                        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
                        style="background-color: #2a3042 !important" />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                </div>
            </div>
        </div>
        <div class="col-6" style="display: flex; justify-content: flex-end">
            <button class="btn-close text-light" v-if="PosHubsSearchMode" @click="cancelAppSearchMode()"></button>

        </div>
    </div>
    <div class="table-responsive">

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table ">
            <thead>
                <tr class="text-light text-center" style="background-color: #2a3042 !important">
                    <th scope="col">{{ $t("pos_hub.table.id") }}</th>

                    <th scope="col">{{ $t("pos_hub.table.name") }}</th>

                    <th scope="col">{{ $t("pos_hub.table.pos_type") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.package_type") }}</th>
                    <th v-if="!$route.params.id" scope="col">{{ $t("pos_hub.table.pos_manager_name") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.pos_hub_admin_name") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.email") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.has_partners") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.single_products_list") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.centric_products") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.centric_inventory") }}</th>
                    <th scope="col">{{ $t("pos_stations.table.subscription_start_date") }}</th>
                    <th scope="col">{{ $t("pos_stations.table.subscription_end_date") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.is_active") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.pos_stations_count") }}</th>
                    <th scope="col">{{ $t("pos_hub.table.actions") }}</th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="(app, index) in posHubs" :key="app" class="text-center">
                    <td>{{ index + 1 }}</td>
                    <td>{{ app?.name }}</td>
                    <td>{{ app?.pos_type?.name }}</td>
                    <td>{{ $i18n.locale == 'en' ?
                        app?.pos_hub_subscriptions[0]?.pos_hub_packge.name_en :
                        app?.pos_hub_subscriptions[0]?.pos_hub_packge.name }}
                    </td>
                    <td v-if="!$route.params.id">{{ app?.pos_hub_manager?.name }}</td>
                    <td>{{ app?.pos_hub_admin?.name }}</td>
                    <td>{{ app?.pos_hub_admin?.email }}</td>

                    <td>
                        <span class="" v-if="app?.has_partners">
                            <img src="/assets/images/icons/check.svg" alt="img" />
                        </span>
                        <span class="" v-else>

                            <img src="/assets/images/icons/cross.svg" alt="img" />
                        </span>
                    </td>
                    <td>
                        <span class="" v-if="app?.single_products_list">
                            <img src="/assets/images/icons/check.svg" alt="img" />
                        </span>
                        <span class="" v-else>

                            <img src="/assets/images/icons/cross.svg" alt="img" />
                        </span>
                    </td>
                    <td>

                        <span class="" v-if="app?.centeric_products">
                            <img src="/assets/images/icons/check.svg" alt="img" />
                        </span>
                        <span class="" v-else>

                            <img src="/assets/images/icons/cross.svg" alt="img" />
                        </span>
                    </td>
                    <td>
                        <span class="" v-if="app?.centeric_ineventory">
                            <img src="/assets/images/icons/check.svg" alt="img" />
                        </span>
                        <span class="" v-else>

                            <img src="/assets/images/icons/cross.svg" alt="img" />
                        </span>
                    </td>

                    <td v-if="app && app.pos_hub_subscriptions[0].subscription_start_date">{{ new
                        Date(app?.created).toLocaleString('en-GB') }}</td>

                    <td v-else>
                        <span class="">

                            <img src="/assets/images/icons/cross.svg" alt="img" />
                        </span>
                    </td>
                    <td v-if="app && app.pos_hub_subscriptions[0].subscription_end_date">{{ new
                        Date(app?.created).toLocaleString('en-GB') }}</td>

                    <td v-else>
                        <span class="">

                            <img src="/assets/images/icons/cross.svg" alt="img" />
                        </span>
                    </td>
                    <td>
                        <span class="" v-if="app?.is_active">
                            <img src="/assets/images/icons/check.svg" alt="img" />
                        </span>
                        <span class="" v-else>

                            <img src="/assets/images/icons/cross.svg" alt="img" />
                        </span>
                    </td>
                    <td>
                        {{ app?.pos_stations.length }}
                    </td>
                    <td class="d-flex">
                        <a class="btn btn-primary me-1" @click="openPosStationsModal(app)" href="javascript: void(0);"
                            role="button">{{ $t("pos_stations.title") }}</a>
                 

                        
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div>
        <!--   Apps  Pagination     -->
        <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
                <a class="page-link" href="javascript: void(0);" @click="getposHubs(page - 1)" aria-label="Previous">
                    <i class="mdi mdi-chevron-left"></i>
                </a>
            </li>
            <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
                <a class="page-link" href="javascript: void(0);" @click="getposHubs(p)">{{
                    p
                }}</a>
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a class="page-link" href="javascript: void(0);" @click="getposHubs(page + 1)" aria-label="Next">
                    <i class="mdi mdi-chevron-right"></i>
                </a>
            </li>
        </ul>
    </div>



    <!-- pos station Modal -->
    <div class="modal fade" id="posStationsModal" tabindex="-1" role="dialog" aria-labelledby="modalTitleId"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalTitleId">
                        {{ $t('pos_stations.title') }}
                    </h5>
                </div>
                <div class="modal-body">


                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-6 mb-3">
                                <div class="search-box chat-search-box w-50">
                                    <div class="position-relative">
                                        <input @keyup="searchPosStations()" type="text"
                                            class="form-control fa-lg text-light"
                                            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')"
                                            v-model="posStations_searchModel"
                                            style="background-color: #2a3042 !important" />
                                        <i class="bx bx-search-alt search-icon text-light"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="display: flex; justify-content: flex-end">
                                <button class="btn-close text-light" v-if="PosStationsSearchMode"
                                    @click="cancelPosStationsSearchMode()"></button>

                            </div>
                        </div>

                        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
                            <thead>
                                <tr class="text-light text-center" style="background-color: #2a3042 !important">
                                    <th scope="col">{{ $t("pos_hub.table.id") }}</th>
                                    <th scope="col">{{ $t("pos_stations.table.name") }}</th>
                                    <th scope="col">{{ $t("pos_stations.table.register_number") }}</th>

                                    <th scope="col">{{ $t("pos_hub.table.package_type") }}</th>
                                    <th scope="col">{{ $t("pos_hub.table.is_active") }}</th>
                                    <th scope="col">{{ $t("pos_stations.table.subscription_start_date") }}</th>
                                    <th scope="col">{{ $t("pos_stations.table.subscription_end_date") }}</th>
                                    <th scope="col">{{ $t("pos_stations.table.brand") }}</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(app, index) in posStations" :key="app" class="text-center">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ app?.name }}</td>
                                    <td>{{ app?.register_number }}</td>
                                    <td>{{ $i18n.locale == 'en' ?
                                        app?.pos_subscriptions[0]?.subscription_packge.name_en :
                                        app?.pos_subscriptions[0]?.subscription_packge.name }}</td>

                                    <td>
                                        <span class="" v-if="app?.is_active">
                                            <img src="/assets/images/icons/check.svg" alt="img" />
                                        </span>
                                        <span class="" v-else>

                                            <img src="/assets/images/icons/cross.svg" alt="img" />
                                        </span>
                                    </td>

                                    <td v-if="app && app.pos_subscriptions[0].subscription_start_date">{{ new
                                        Date(app?.created).toLocaleString('en-GB') }}</td>

                                    <td v-else>
                                        <span class="">

                                            <img src="/assets/images/icons/cross.svg" alt="img" />
                                        </span>
                                    </td>
                                    <td v-if="app && app.pos_subscriptions[0].subscription_end_date">{{ new
                                        Date(app?.created).toLocaleString('en-GB') }}</td>

                                    <td v-else>
                                        <span class="">

                                            <img src="/assets/images/icons/cross.svg" alt="img" />
                                        </span>
                                    </td>
                                    <td style=" min-width: 100px;">
                                        <img style="width: 64px; height: 64px" :src="app.img ? `${$store.state.mediaUrl}/${app?.img?.split('public/')[1]
                                            }` : '/assets/images/default_image.png'" alt="" />
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                        <!-- end  table -->
                        <!-- Button trigger modal -->

                        <div v-if="posStations_tot_page == 20">
                            <!--   Apps  Pagination     -->
                            <ul class="pagination pagination-rounded justify-content-center mb-2">
                                <li class="page-item" :class="{ disabled: page == 1 }">
                                    <a class="page-link" href="javascript: void(0);" @click="getPosStations(page - 1)"
                                        aria-label="Previous">
                                        <i class="mdi mdi-chevron-left"></i>
                                    </a>
                                </li>
                                <li class="page-item" :class="{ active: p == page }" v-for="p in posStations_tot_page"
                                    :key="p">
                                    <a class="page-link" href="javascript: void(0);" @click="getPosStations(p)">{{
                                        p
                                    }}</a>
                                </li>

                                <li class="page-item" :class="{ disabled: page == total_pages }">
                                    <a class="page-link" href="javascript: void(0);" @click="getPosStations(page + 1)"
                                        aria-label="Next">
                                        <i class="mdi mdi-chevron-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        {{ $t('popups.close') }}
                    </button>

                </div>
            </div>
        </div>
    </div>

    <!--end-->
</template>

<style scoped>
.btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
}
</style>
